import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { rootPath, mySessKey } from '../../shared/utils';
import LoadSpinner from '../../shared/loadSpinner';

const default_org = { 
    org_parent_name:"", org_address:"", org_city:"", org_state:"", org_zip:"", 
    uniform_invoice_title:"",  league_invoice_title:"", 
    uniform_invoice_sub_text:"",  league_invoice_sub_text:"",
    uniform_invoice_default_logo: false, league_invoice_default_logo: false
};
const register_org_fields = [
    { "title":"Billing Organization", "name":"org_parent_name", "sz":4, "type":"text" }, 
    { "title":"Billing Address", "name":"org_address", "sz":4, "type":"text" },
    { "title":"Billing Area", "name":"billing_default", "sz":2, "type":"data-select", "data_field": "billing_areas" },  
    { "title":"City", "name":"org_city", "sz":4, "type":"text" },
    { "title":"State", "name":"org_state", "sz":3, "type":"text" }, 
    { "title":"Zip", "name":"org_zip", "sz":3, "type":"text" },
    { 
        "title":"Uniform Invoice Header", "sz":5, "type":"input-section" ,
        "name":["uniform_invoice_title", "uniform_invoice_sub_text","uniform_invoice_default_logo"], 
        "placeholders":[
            "Please Enter Title For This Invoice", 
            `Please Enter Address Or Subtitle, \nFormat each line accordingly`
        ]         
    },
    { 
        "title":"League Invoice Header", "sz":5, "type":"input-section",
        "name":["league_invoice_title", "league_invoice_sub_text","league_invoice_default_logo"], 
        "placeholders":[
            "Please Enter Title For This Invoice", 
            `Please Enter Address Or Subtitle, \nFormat each line accordingly`
        ]         
    },
];

class AccountUserRow extends Component{
    constructor(props) { 
        super(props);

        this.state = {
            edit: false, hasEdits: false, form: {
                name:"", org_name:"", org_id:""
            }
        }

        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.filterOrgList = this.filterOrgList.bind(this);
        this.updateForm = this.updateForm.bind(this);
    }

    toggleEdit() {
        try {
            this.setState({ edit: !this.state.edit });
        }
        catch(ex){
            console.log(`Toggling Edit: ${ex}`);
        }
    }

    handleChange(e){
        try {
            let name = e.target.name;
            let update_form = {...this.state.form, [name]: e.target.value };

            const hasEdits = (
                (this.props.account_item.name != update_form.name) ||
                (this.props.account_item.org_name != update_form.org_name) ||
                (this.props.account_item.org_id != update_form.org_id)
            );

            this.setState({ hasEdits: hasEdits, form: { ...update_form }});
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    filterOrgList(){
        let ret = [];
        try {
            if(this.props?.purchase_org_list) {
                ret = this.props.purchase_org_list.filter((org) => {
                    return org?._id;
                });
            }
        }
        catch(ex){
            console.log(`Filtering Org List: ${ex}`);
        }

        return ret;
    }

    updateForm(){
        const self = this;
        try {
            const hasEdits = (
                (this.props.account_item.name != this.state.form.name) ||
                (this.props.account_item.org_name != this.state.form.org_name) ||
                (this.props.account_item.org_id != this.state.form.org_id)
            );

            if(hasEdits && !this.props.loading){
                this.props.saveRow(this.props.account_item._id, this.state.form,(ret)=>{
                    self.setState({ edit: false, hasEdits: false });
                });
            }
        }
        catch(ex){
            console.log(`Updating Form: ${ex}`);
        }
    }

    componentDidMount() {
        this.setState({
            edit: false, hasEdits: false,  
            form: { 
                name:this.props.account_item.name, 
                org_name:this.props.account_item.org_name, 
                org_id:this.props.account_item.org_id
            }
        });
    }

    render(){  
        return(
            <>
                <td>{this.props.account_item.name}</td>
                <td>{this.props.account_item.email}</td>
                {this.state.edit ?
                    <>
                        <td><input type="text" name="org_name" placeholder='Enter Org Name' value={this.state.form.org_name} onChange={this.handleChange}/></td>
                        <td>
                            <select name="org_id" value={this.state.form.org_id} onChange={this.handleChange}>
                                <option hidden>Select An Organization</option>
                                {this.filterOrgList().map((op, l) =>
                                    <option value={op._id} key={l}>{op.name}</option>
                                )}
                            </select>
                        </td>
                    </> :
                    <>
                        <td>{this.props.account_item.org_name}</td>
                        {this.props.account_item?.org_info.length > 0 ?
                            <td>{this.props.account_item.org_info[0].org_address}, {this.props.account_item.org_info[0].org_city}, {this.props.account_item.org_info[0].org_state} {this.props.account_item.org_info[0].org_zip}</td>
                            : <td></td>
                        }
                    </>
                }
                
                <td>
                    {this.state.edit ?
                        <i className={`edit-user-btn far fa-save ${this.state.hasEdits ? '' : 'disable'}`} onClick={this.updateForm}/> :
                        <i className="edit-user-btn far fa-edit" onClick={this.toggleEdit}/>
                    }
                </td>
            </>
        );
    }
}

class AdminUsersTool extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading: false, contact_list:[], contact_search:"",
            purchase_org_list:[], sel_org_info:"", org_info: default_org,
            billing_areas:[]
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.getOrgList = this.getOrgList.bind(this);
        this.getBillingToggleList = this.getBillingToggleList.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.addressItemField = this.addressItemField.bind(this);
        this.saveRow = this.saveRow.bind(this);
    }

    handleChange(e){
        try {
            let self = this, name = e.target.name;
            
            let update_form = {[name]: e.target.value };

            if(name === "sel_org_info") {
                let idx = parseInt(e.target.value);
                update_form["org_info"] = (idx == this.state.purchase_org_list.length - 1 ? 
                        {...default_org } : this.state.purchase_org_list[idx].value
                );
            }

            this.setState({ ...update_form }, ()=>{
                if(name === "contact_search"){
                    self.getContacts();
                }
            });
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleFormChange(e, type){
        try {
            let tmpData = {...this.state[type] }, name = e.target.name;

            // if(name in tmpData) {
                if(e.target.type === "number"){
                    tmpData[name] = e.target.value.split('.').join("");
                }
                else if(e.target.type === 'toggle'){
                    tmpData[name] = !tmpData[name];
                }
                else {
                    tmpData[name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
                }
                this.setState({ [type]: tmpData });
            // }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    getContacts(){
        let self = this;
        try {
            this.setState({ loading: true }, ()=>{ 
                let token = localStorage.getItem(mySessKey),
                    search_query = (self.state.contact_search.length > 0 ? `/${self.state.contact_search}` : '');

                axios.get(`${rootPath}/v2/api/auth/purchase_accounts${search_query}`, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting contacts data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting to retrieving contact list [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            self.setState({ contact_list: response.data.results });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Contacts (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Contacts: ${ex}`);
        }
    }

    getOrgList(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/purchase/organization`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving organizations [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            let tmp_org_list = [{ "name": "Add New Address", "value": { ...default_org, uniform_invoice_title:"Lee Lee Kiddz",  league_invoice_title:"Lee Lee Kiddz"} }]
                            response.data.results.forEach((item)=>{
                                tmp_org_list.push({ "_id":item._id, "name":item.org_parent_name, "value": item });
                            });

                            // Set State
                            self.setState({ purchase_org_list: tmp_org_list });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Organizations (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Organizations: ${ex}`);
        }
    }

    getBillingToggleList() { 
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/merchant`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(`[Error] Getting Toggles: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving toggle list [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            let tmp_billing_area = [
                                { "name": "N/A", "key":""},
                                ...response.data.results
                            ]
                            self.setState({ billing_areas: tmp_billing_area });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Toggles (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Toggles: ${ex}`);
        }
    }

    saveAddress(){
        try{
            let org_form_validation = validateForm("org_info", this.state.org_info), self = this;

            if(org_form_validation.length > 0){
                toast.warning(`Please Check The Following Fields ${org_form_validation.join(",")}`, { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark", });
            }
            else {
                this.setState({ loading: true}, ()=>{
                    let postData = { ...self.state.org_info };
                    axios.post(`${rootPath}/v2/api/purchase/organization${self.state.org_info._id ? `/${self.state.org_info._id}` : ''}`, postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Saving/Updating Address(E1): ", response.data.error);
                                toast.error(`${response.data.error}`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                            }
                            else {
                                toast.success(`Successfully Saved/Updated Address`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                                self.getOrgList();
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`Error with Sign-up: ${ex}`);
            toast.error("Sorry, There was an issue attempting to Save/Update Address [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                draggable: true, progress: undefined, theme: "dark", });
        }
    }

    addressItemField(item){
        try {
            switch(item.type){
                case "toggle":
                    return <div className={`toggle-container ${(this.state.sel_org_info.length > 0 ? "" : "disabled")}`}>
                                <div className={`toggle-btn ${(this.state.org_info?.[item.name] ? "sel" : "")}`} onClick={this.handleToggleChange}/>
                            </div>;
                case "text":
                    return <input type="text" name={`${item.name}`} value={this.state.org_info?.[item.name]} onChange={(e)=> this.handleFormChange(e,"org_info")} disabled={(this.state.sel_org_info.length > 0 ? "" : "disabled")}/>;
                case "input-section":
                    return <div className='input-section-container'>
                                <input type="text" placeholder={item?.placeholders[0]} name={`${item.name[0]}`} value={this.state.org_info?.[item.name[0]]} onChange={(e)=> this.handleFormChange(e,"org_info")} disabled={(this.state.sel_org_info.length > 0 ? "" : "disabled")}/>
                                <div className='icon-selection-container'>
                                    <span>Show LeeLee Logo On Invoice(s)?</span>
                                    <div className={`selection-toggle ${(this.state.org_info?.[item.name[2]] ? 'sel' : '')}`} onClick={(e)=> this.handleFormChange({"target":{ "name": item.name[2], "type":"toggle" }},"org_info")}/>
                                </div>
                                <textarea rows={3} placeholder={item?.placeholders[1]} name={`${item.name[1]}`} value={this.state.org_info?.[item.name[1]]} onChange={(e)=> this.handleFormChange(e,"org_info")} disabled={(this.state.sel_org_info.length > 0 ? "" : "disabled")}/>
                            </div>;
                case "data-select":
                    return <select name={`${item.name}`} value={this.state.org_info?.[item.name] ?? ""} onChange={(e)=> this.handleFormChange(e,"org_info")} disabled={(this.state.sel_org_info.length > 0 ? "" : "disabled")} >
                                <option hidden>Select An Item</option>
                                {this.state[item.data_field].map((op, l) =>
                                    <option value={op?.key} key={l}>{op?.name}</option>
                                )}
                            </select>;
                default:
                    return <></>;
            }
        }   
        catch(ex){
            console.log(`[Error] building address Item Field`)
            return <></>
        }
    }

    saveRow(_id, form, cb) {
        const self = this;
        try {
            this.setState({ loading: true}, ()=>{
                let postData = { _id:_id, ...form },
                    token = localStorage.getItem(mySessKey);

                axios.post(`${rootPath}/v2/api/auth/purchase_account`, postData, { headers: {'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Saving/Updating Organization(E1): ", response.data.error);
                            toast.error(`${response.data.error}`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark", });
                        }
                        else {
                            toast.success(`Successfully Saved/Updated Organization`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark", });
                            
                            cb(true);
                            self.getContacts();
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`Saving Row: ${ex}`);
        }
    }

    componentDidMount(){ 
        this.getContacts();
        this.getOrgList();
        this.getBillingToggleList();
    }

    render(){  
        return(
            <div className="admin-tool-container contacts">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className='store-config-container'>
                    <div className='title'>
                        <i className="fas fa-users" />
                        <span>User Accounts</span>
                    </div>
                </div>

                <div className='address-container'>
                    <div className='address-title'>
                        <h1>User Addresses</h1>
                        <div className={`save-btn ${this.state.sel_org_info.length > 0 ? '': 'disabled'}`} onClick={this.saveAddress}>Save</div>
                    </div>
                    <div className={`form-item sz-10 spacer`}>
                        <span className='title'>Select Organization Address</span>
                        <select name="sel_org_info" value={this.state.sel_org_info} onChange={this.handleChange}>
                            <option hidden>Select An Address</option>
                            {this.state?.purchase_org_list?.map((op, l) =>
                                <option value={l} key={l}>{op.name}</option>
                            )}
                        </select>
                    </div>
                    {register_org_fields.map((item,i) =>
                        <div className={`form-item sz-${item.sz}`} key={i}>
                            <span className='title'>{item.title}</span>
                            {this.addressItemField(item)}
                        </div>
                    )}                          
                </div>

                <div className='contact-container'>
                    <div className='contact-title'>
                        <h1>User Accounts</h1>
                        <input type="text" name="contact_search" placeholder='Search User Accounts' value={this.state.contact_search} onChange={this.handleChange}/>
                    </div>
                    <table className='contact-table'>
                        <thead>
                            <tr className='table-header'>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Orginization Name</th>
                                <th>Orginization Address</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.contact_list.map((item,i)=>
                                <tr key={i} className='contact-row'>
                                    <AccountUserRow 
                                        account_item={item} purchase_org_list={this.state.purchase_org_list} 
                                        loading={this.state.loading} saveRow={this.saveRow}
                                    />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default AdminUsersTool;


function validateForm(type, form) {
    let ret = [];
    try {
        let fields = { 
            "org_info":[
                { "name":"org_parent_name", "type": "text" }, { "name":"org_address", "type": "text" },  
                { "name":"org_city", "type": "text" }, { "name":"org_state", "type": "text" },  { "name":"org_zip", "type": "text" },
                { "name":"uniform_invoice_title", "type": "text" },  { "name":"league_invoice_title", "type": "text" }, 
                // { "name":"billing_default", "type": "text" }
            ]
        }

        if(type in fields){
            for(let i =0; i < fields[type].length; i++){
                let field_item = fields[type][i];
                switch(field_item.type){
                    case "text":
                        if(form[field_item.name]?.length <= 0){ ret.push(field_item.name);}
                        break;
                    case "email":
                        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                        if(form[field_item.name]?.length <= 0 || !form[field_item.name].match(validRegex)){
                            ret.push(field_item.name);
                        }
                        break;
                    default:
                        break;

                }
            }
        }
        else { ret.push("error"); }
    }
    catch(ex){
        console.log(`[Error] Validating Form: ${ex}`);
        ret.push("error");
    }
    return ret;
}
import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { buildFinalInvoice, currencyFormat, rootPath, mySessKey, parseToken } from '../../shared/utils';
import LoadSpinner from '../../shared/loadSpinner';
import { format } from 'date-fns';

const store_config = {
    "league":{
        "title":"League Invoice"
    },
    "uniform":{
        "title":"Uniform Invoice"
    }
}

class Invoices extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false, title:null, invoiceList: [], sub_total:0, total: 0, item_count: 0,
            invoice_detail_display: [], config_values:{}
        }

        this.generateInvoice = this.generateInvoice.bind(this);
        this.toggleInvoiceDetailsDisplay = this.toggleInvoiceDetailsDisplay.bind(this);
        this.processInvoiceList = this.processInvoiceList.bind(this);
        this.submitInvoice = this.submitInvoice.bind(this);
        this.getConfig = this.getConfig.bind(this);
    }

    generateInvoice(){
        try {
            let tmp_invoice_data = buildFinalInvoice(this.props.invoiceList);

            this.setState({ 
                title: (this?.props?.invoiceType ? store_config[this.props.invoiceType].title : ""),
                ...tmp_invoice_data
            });
        }
        catch(ex){
            console.log(`Error Generating Invoice: ${ex}`);
        }
    }

    toggleInvoiceDetailsDisplay(idx){
        try {
            let tmp_display = [...this.state.invoice_detail_display];
            tmp_display[idx] = !tmp_display[idx];
            
            this.setState({ invoice_detail_display: tmp_display });
        }
        catch(ex){
            console.log(`Error Toggling Invoice display: ${ex}`);
        }
    }

    processInvoiceList(invoiceList){
        let ret = [];
        try {
            // Clean List
            let tmp_list = JSON.parse(JSON.stringify(invoiceList));

            for(let i=0; i < tmp_list.length; i++){
                if(tmp_list[i].type === "uniform"){
                    ret.push({...tmp_list[i], line_item: (i+1)});
                }
                else {
                    let min_item_total = (isNaN(tmp_list[i].min_amount) ? 1 : Number(tmp_list[i].min_amount)),
                        add_item_total = tmp_list[i].add_item_count,
                        item_price = tmp_list[i].item_price, 
                        add_price = tmp_list[i].add_item_price,
                        coach_count = tmp_list[i].league_coaches,
                        coach_price = tmp_list[i].coach_price;
                    
                    // Additional Players Line Item
                    if(add_item_total > 0){
                        // Main Players Line Item                   
                        ret.push({ 
                            ...tmp_list[i], line_item: (i+1), 
                            sub_item_count: min_item_total, 
                            sub_total: (min_item_total * item_price)
                        });

                        ret.push({
                            ...tmp_list[i], line_item: (i+1),
                            title: `${tmp_list[i].title} [Additional Players]`,
                            sub_item_count: add_item_total,
                            sub_total: (add_item_total * add_price),
                            item_price: add_price
                        });
                    }
                    else {
                        ret.push({...tmp_list[i], line_item: (i+1)});
                    }

                    if(coach_count > 0){
                        ret.push({
                            ...tmp_list[i], line_item: (i+1),
                            title: `${tmp_list[i].title} [League Coaches]`,
                            sub_item_count: coach_count,
                            sub_total: (coach_count * coach_price),
                            item_price: coach_price
                        });
                    }
                }
            }
        }
        catch(ex){
            console.log(`[Error] Processing Invoice List: ${ex}`);
        }

        return ret;
    }

    submitInvoice() {
        let self = this;
        try {
            let token = localStorage.getItem(mySessKey);
            let { localUser } = parseToken(token);
            if(this.state.config_values?.min_amount > this.state.item_count){
                toast.error(`Sorry, you have not reached the minimum item count of ${this.state.config_values?.min_amount}`, { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                        draggable: true, progress: undefined, theme: "dark" });
            }
            else if(localUser.admin){
                toast.warning(`Please log out of admin account and into a user account to build and submit invoice`, { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark" });
            }
            else if(localUser._id){
                this.setState({ loading: true}, ()=> { 
                    // Build Invoice
                    let postData = {
                        account_id: localUser._id, status: 'NEW', 
                        total_price: self.state.total,
                        sub_total: self.state.sub_total,
                        discount: self.state.discount,
                        line_items: self.processInvoiceList(self.state.invoiceList)                        
                    }

                    postData.line_items.forEach((item) => {
                        delete item["img_list"]; delete item["list_title"]; delete item["option_list"];
                        return item;
                    });

                    if(postData.line_items.length == 0){
                        toast.error(`Sorry, there was an error while submitting your cart please contact system admin [EC-230]`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                    }
                    else {
                        axios.post(`${rootPath}/v2/api/purchase/quote`, postData, {headers:{'Content-Type': 'application/json'}})
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(`[Error] Submitting invoice: ${response.data.error}`);
                                toast.error(`Sorry, There was an issue submitting this invoice [Please Contact Site Admin]`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark" });
                            }
                            else { 
                                self.props.resetInvoice();

                                toast.success(`Successfully Submitted invoice`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark" });
                            }
                        })
                        .catch(function (error) {
                            console.log("[Error] Submitting invoice (E2)",error);
                            toast.error(`Sorry, There was an issue submitting your invoice [Please Contact Site Admin]`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        })
                        .then(function(){
                            self.setState({ loading: false });
                        });   
                    }
                });
            }
        }
        catch(ex){
            console.log(`Error: Submitting Invoice: ${ex}`);
        }
    }

    getConfig(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                let config_name = this.props.invoiceType.toLowerCase();
                axios.get(`${rootPath}/v2/api/purchase/config?name=${config_name}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving config info [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set Config Data
                            let res_data = response.data.results[0];

                            // Set State
                            self.setState({ config_values: res_data });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Config (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Config: ${ex}`);
        }
    }

    componentDidMount(){ 
        if(this.props.invoiceType){
            this.generateInvoice();
            this.getConfig();
        }
    }

    render(){  
        return(
            <div className="store-invoice">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                {/* Store Header */}
                <div className='store-title'>{this.state.title}</div>

                <div className='store-invoice-body'>
                    <div className='invoice-list-container'>
                        {this.state.invoiceList.map((item, i) => 
                            <div className='list-item-container' key={i}>
                                <div className='list-item'>
                                    <div className='item-img'>
                                        {item.img_list.length > 0 ?
                                            <img src={item.img_list[0]} /> :
                                            <div className='empty-img' />
                                        }
                                    </div>
                                    <div className='item-details'>
                                        <div className='title'>{item.title}</div>
                                        <div className='item-btn-container'>
                                            <div className={`item-btn-link ${this.state.invoice_detail_display[i] ? 'open' : ''}`} onClick={()=> this.toggleInvoiceDetailsDisplay(i)}>
                                                <i className={`fas ${this.state.invoice_detail_display[i] ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                                <span>Details</span>
                                            </div>
                                            <div className='item-btn-link' onClick={()=> this.props.editInvoiceItem(i, item)}>
                                                <i className="far fa-edit" />
                                                <span>Edit</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='item-subtotal-details'>
                                        <div className='item-subtotal'>{currencyFormat(item.sub_total)}</div>
                                    </div>

                                    <div className='item-clear' onClick={()=> this.props.removeInvoiceItem(i, ()=>{ this.generateInvoice(); })}>
                                        <i className="fas fa-trash-alt" />
                                    </div>
                                </div>

                                <div className={`item-detail-container ${this.state.invoice_detail_display[i] ? 'open' : ''}`}>
                                    {item.jersey_info?.has_jersey_info ?
                                        <div className='item-personalize-container'>
                                            <div className='img-container'>
                                                {item.jersey_info.jersey_img && <img src={item.jersey_info.jersey_img} /> }
                                            </div>
                                            <div className='team-info-container'>
                                                <div className='team-info-item'>
                                                    <div className='title'>Team Name</div>
                                                    <span>{item.jersey_info.team_name}</span>
                                                </div>
                                                <div className='team-info-item'>
                                                    <div className='title'>Design Notes</div>
                                                    <span>{item.jersey_info.jersey_description}</span>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                    <div className='item-detail-table-container'>
                                        {item.type === "league" &&
                                            <div className='league-info'>
                                                <div className='info-item'><b>League Dates</b></div>
                                                <div className='info-item'>
                                                    <span>{format(item.start_dt, "MMM. dd, yyyy")}</span>
                                                    -
                                                    <span>{format(item.end_dt, "MMM. dd, yyyy")}</span>
                                                </div>
                                            </div>
                                        }
                                        <table className='detail-table'>
                                            <thead>
                                                <tr className='table-header'>
                                                    {item.detail_items.map((di, j) =>
                                                        <th key={j}>{di.title}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.item_details.map((detail,k) =>
                                                    <tr key={k}>
                                                        {item.detail_items.map((di, l) =>
                                                            <td key={l}>{detail[di.title]}</td>
                                                        )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        {/* Pricing Details */}
                                        <table className='detail-table pricing'>
                                            <thead>
                                                <tr className='table-header'>
                                                    <th>Details</th>
                                                    <th>Qty</th>
                                                    <th>Pricing per</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                {this.props.invoiceType === "league" ?
                                                    <>
                                                        <tr>
                                                            <td>League Players</td>
                                                            <td>{(item.add_item_count > 0) ? item.min_amount : item.sub_item_count}</td>
                                                            <td>{currencyFormat(item.item_price)}</td>
                                                        </tr>

                                                        {item.add_item_count > 0 &&
                                                            <tr>
                                                                <td>Additional Players</td>
                                                                <td>{item.add_item_count}</td>
                                                                <td>{currencyFormat(item.add_item_price)}</td>
                                                            </tr>
                                                        }

                                                        {(item?.league_coaches > 0 && item?.coach_price > 0) &&
                                                            <tr>
                                                                <td>Coaches</td>
                                                                <td>{item.league_coaches}</td>
                                                                <td>{currencyFormat(item.coach_price)}</td>
                                                            </tr>
                                                        }
                                                    </> :
                                                    <tr>
                                                       <td>Items</td> 
                                                       <td>{item.sub_item_count}</td>
                                                       <td>{currencyFormat(item.item_price)}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='invoice-total-details'>
                        <div className='it-container'>
                            <p>The following is your custom invoice based on the items that you added.  If all of the items are correct feel free to select the 'Finialize Invoice' button.  
                                Which will generate and save your custom invoice.</p> 
                            <p>Next you will need to return and upload your purchase order from your governing organization for final processing.</p>
                            <p>If you have any questions feel free to contact: <a href="mailto:kevinricks@leeleeff.com" target="_blank">kevinricks@leeleeff.com</a></p>
                        </div>

                        <div className='it-container'>
                            <div className='total-info'>
                                <span>Sub-Total:</span>
                                <span>{currencyFormat(this.state.sub_total)}</span>
                            </div>
                            {(this.state?.discount != null) ?
                                <div className='total-info wrap'>
                                    <span>Discount:</span>
                                    <span>{this.state?.discount?.percentage}%</span>
                                    <div className='discount-description'>{this.state?.discount?.title}</div>
                                </div> : <></>
                            }                            

                            <div className='total-info'>
                                <span className='total'>Total:</span>
                                <span className='total'>{currencyFormat(this.state.total)}</span>
                            </div>
                        </div>

                        <div className='it-container'>
                            <div className='it-btn-container'>
                                <div className='it-btn' onClick={this.submitInvoice}><i className="far fa" /> <span>Submit Invoice</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Invoices;
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import Select from 'react-select';
import Modal from '@material-ui/core/Modal';
import { format } from "date-fns";
import generatePDF from 'react-to-pdf';

import LoadSpinner from '../shared/loadSpinner';

import { colourStyles } from '../shared/utils';

let pdfRef;

class LeagueSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leagues:[], 
            selectedLeagueSchedule:null, 
            selectedLeagueStandings:null,
            selectedLeagueTeams:[],
            selectedLeagueTeamSchedules:null,
            selectedSchedule: null, selectedTeam: null,
            selLeague: 0, selDay:0, loading: false,
            openModal: false
        }  

        this.dateText = this.dateText.bind(this);
        this.minText = this.minText.bind(this);
        this.controlSlider = this.controlSlider.bind(this);
        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
        this.getLeagueSchedule = this.getLeagueSchedule.bind(this);
        this.getNextWeek = this.getNextWeek.bind(this);
        this.changeTeam = this.changeTeam.bind(this);
        this.getAwayTeam = this.getAwayTeam.bind(this)

        /* References */
        this.leagueConList = React.createRef();
        this.dateConList = React.createRef();
        this.targetRef  = React.createRef();
    }

    dateText(text, type){
        var ret = "";
        try {
            var d = new Date();

            if(type === "time") {
                d = new Date(parseInt(text));
            }
            else {
                var tmpDate = text.split("-");
                if(tmpDate.length >= 3) {
                    var y = tmpDate[2], m = parseInt(tmpDate[0]) - 1, day = tmpDate[1];
                    d = new Date(y, m, day);
                }
            }
            
            var monthList = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            
            switch(type){
                case "month":
                    ret = monthList[d.getMonth()];
                    break;
                case "date":
                    ret = d.getDate();
                    break;
                case "time":
                    var h = d.getHours(), m = d.getMinutes();
                    ret = (h > 12 ? h-12 : h) + ":" + (m < 10 ? "0"+m : m) + (h > 12 ? " PM" : " AM");
                    break;
                case "datetime":
                    ret = d.getTime();
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Minimizing Text: ", ex);
        }

        return ret;
    }

    minText(text){
        var ret = "";
        try {
            var maxLen = 35;
            ret = (text.length <= maxLen ? text : text.slice(0,maxLen) + "...");
        }
        catch(ex){
            console.log("[Error] Minimizing Text: ", ex);
        }

        return ret;
    }

    controlSlider(ref, dir, sz){
        try {
            var scrollSz = 250;   
            if(sz === 0) {
                ref.current.scrollTop = ref.current.scrollTop + (dir * scrollSz);  
            }       
            else {
                ref.current.scrollLeft = ref.current.scrollLeft + (dir * scrollSz);  
            } 
                      
        }
        catch(ex){
            console.log("Error with slider control: ", ex);
        }
    }

    loadLeagueInfo(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { count: -1, type: "" };
                axios.post(self.props.rootPath + "/api/getLeagueEvents", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting League Event Data (E1): ", response.data.error);
                        }
                        else {
                            self.setState({ leagues: response.data.results }, ()=> { 
                                if(self.state.leagues.length > 0) {
                                    self.getLeagueSchedule(self.state.leagues[0].programId);
                                }
                            });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] loading league info list: ",ex);
        } 
    }

    getLeagueSchedule(leagueId) {
        var self = this;
        try {
            this.setState({ loading: true, selLeague: leagueId }, ()=> {
                axios.get(self.props.rootPath + "/api/league/schedule/"+leagueId, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting League Schedule (E1): ", response.data.error);
                        }
                        else {
                            let teams = (response.data.results?.teams ? 
                                [
                                    { name: "Select Team To View Schedule", id: 0 }, 
                                    ...Object.values(response.data.results?.teams)
                                ] : []);
                           
                            self.setState({ 
                                selectedLeagueSchedule: response.data.results?.schedule,
                                selectedLeagueStandings: response.data.results?.standings,
                                selectedLeagueTeams: teams.map((t)=> { return { label: t.name, value: t.id }; }),
                                selectedLeagueTeamSchedules: response.data.results?.teamSchedules,
                                selDay: self.getNextWeek(response.data.results.schedule) });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] League Schedule: ",ex);
        } 
    }

    getNextWeek(selLeague){
        var ret = 0, self = this;
        try {
            var curDate = new Date();
            curDate.setDate(curDate.getDate()-1);

            for(var i = 0; i < selLeague.length; i++){
                if(curDate.getTime() <= self.dateText(selLeague[i].day, "datetime")){
                    break;
                }
                else if((i + 1) < selLeague.length){
                    ret++;
                }
            }
        }
        catch(ex){
            console.log("[Error] Getting Next Week: ",ex);
        }
        return ret;
    }

    changeTeam(newValue, _actionMeta){
        try {
            let tmpScheduleDict = this.state.selectedLeagueTeamSchedules, tmpSel = null;
            if(tmpScheduleDict && newValue.value in tmpScheduleDict){
                tmpSel = tmpScheduleDict[newValue.value];
            }

            console.log(tmpSel);
            this.setState({ selectedSchedule: tmpSel, selectedTeam: newValue, openModal: tmpSel != null });
        }
        catch(ex){
            console.log(`[Error] changing team: ${ex}`);
        }
    }

    getAwayTeam(game){
        let ret = "";
        try {
            let selTeamId = this.state.selectedTeam?.value;

            ret = (selTeamId == game.team1Id ? game.team2 : game.team1);
        }
        catch(ex){
            console.log(`Error Getting Away Team`);
        }
        return ret;
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "League Schedule";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/schedule');
        this.loadLeagueInfo();
    }

    render(){  
        return(
            <div className="page-body leagueschedule">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <section className="slim-landing">                   
                    <div className="slim-landing-text">League Schedule</div>                       
                </section>

                <section className="schedule-tool-container">
                    <div className="ctrl-list league-list-container">
                        <i className="lrg fas fa-chevron-left" onClick={()=> this.controlSlider(this.leagueConList, -1, 0)}/>
                        <i className="sm fas fa-chevron-left" onClick={()=> this.controlSlider(this.leagueConList, -1, 1)}/>

                        <div className="scroll-container" ref={this.leagueConList}>
                            {this.state.leagues.map((league,i) =>
                                <div className={"list-item" + (league.programId == this.state.selLeague ? " sel" : "")} key={i} onClick={()=> this.getLeagueSchedule(league.programId)}>{this.minText(league.name)}</div>
                            )}
                        </div>
                        <i className="lrg fas fa-chevron-right" onClick={()=> this.controlSlider(this.leagueConList, 1, 0)}/>
                        <i className="sm fas fa-chevron-right" onClick={()=> this.controlSlider(this.leagueConList, 1, 1)}/>
                    </div>

                    <div className="ctrl-list date-list-container">
                        <i className="lrg fas fa-chevron-left" onClick={()=> this.controlSlider(this.dateConList, -1, 0)}/>
                        <i className="sm fas fa-chevron-left" onClick={()=> this.controlSlider(this.dateConList, -1, 1)}/>

                        {(this.state.selectedLeagueSchedule != null) &&
                            <div className="scroll-container" ref={this.dateConList}>
                                {this.state.selectedLeagueSchedule.map((date,i) =>
                                    <div className={"list-item" + (i == this.state.selDay ? " sel" : "")} key={i} onClick={() => this.setState({selDay: i})}>
                                        <span className="bold">{this.dateText(date.day, "date")}</span>
                                        <span>{this.dateText(date.day, "month")}</span>
                                    </div>
                                )}
                            </div>
                        }
                        <i className="lrg fas fa-chevron-right" onClick={()=> this.controlSlider(this.dateConList, 1, 0)}/>
                        <i className="sm fas fa-chevron-right" onClick={()=> this.controlSlider(this.dateConList, 1, 1)}/>
                    </div>
                    
                    <div className='full-schedule-container'>
                        {(this.state.selectedLeagueSchedule != null && this.state.selectedLeagueSchedule[this.state.selDay]) ?
                            <div className="schedule-list-container">
                                {this.state.selectedLeagueSchedule[this.state.selDay].schedule.map((timeSlot, i) =>
                                    <div className="time-slot" key={i}>
                                        <div className="time-info"><span>{this.dateText(timeSlot.time, "time")}</span></div>
                                        <div className="game-list-container">
                                            {timeSlot.games.map((game,j) =>
                                                <div className='game-container' key={j}>
                                                    <div className="game-info">
                                                        <div className="team-container">
                                                            <div className="team-logo"><div className="img-icon"><img src="/imgs/logo/leeleekiddz_logo.jpg"/></div></div>
                                                            <div className="team-logo spacer">VS</div>
                                                            <div className="team-logo"><div className="img-icon"><img src="/imgs/logo/leeleekiddz_logo.jpg"/></div></div>
                                                        </div>

                                                        <div className="team-container">
                                                            <div className={"team-info " + (game.team1Result != null ? game.team1Result.toLowerCase() : "")}>
                                                                <div className="info-text">
                                                                    <span>{game.team1}</span>
                                                                    {game.team1Score != null &&
                                                                        <span className="score">({game.team1Score})</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="team-info spacer text">VS</div>
                                                            <div className={"team-info " + (game.team2Result != null ? game.team2Result.toLowerCase() : "")}>
                                                                <div className="info-text">
                                                                    <span>{game.team2}</span>
                                                                    {game.team2Score != null &&
                                                                        <span className="score">({game.team2Score})</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>                                                    
                                                    </div>
                                                    <div className='location-info'>
                                                        {game.locationName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div> :
                            <div className={"schedule-list-container" + (!this.state.loading ? " empty":"")} />
                        }

                        <div className="add-schedule-list-container">
                            {(this.state.selectedLeagueTeams != null && this.state.selectedLeagueTeams.length > 0) &&
                                <div className="standing-list-container teams">
                                    <div className="standings-title">Team Schedules</div>

                                    <div className='team-select-container'>
                                        <Select className="select-pill" name="team-list" styles={colourStyles} onChange={this.changeTeam} options={this.state.selectedLeagueTeams} defaultValue={this.state.selectedLeagueTeams[0]} isClearable={false} isSearchable={true} />
                                    </div>
                                </div>
                            }
                            {(this.state.selectedLeagueStandings != null && this.state.selectedLeagueStandings.length > 0) &&
                                <div className="standing-list-container standings">
                                    <div className="standings-title">Standings</div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th><div>W</div></th><th><div>L</div></th>
                                                <th><div>T</div></th><th><div>PF</div></th>
                                                <th><div>PA</div></th><th><div>PD</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.selectedLeagueStandings.map((team, i) =>
                                                <tr key={i}>
                                                    <td className="lrg"><b>{team.name}</b></td>
                                                    <td>{team.w}</td><td>{team.l}</td>
                                                    <td>{team.t}</td><td>{team.pf}</td>
                                                    <td>{team.pa}</td><td>{team.pf - team.pa}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </section>

                {/* Schedule Modal */}
                <Modal open={this.state.openModal} onClose={()=>this.setState({ openModal: false, selectedSchedule: null })}>
                    <div className="modal-body" >
                        <div className='print-btn-container'>
                            <div className='print-btn link-btn c2' onClick={() => generatePDF(this.targetRef, {filename: `${this.state.selectedTeam?.label.split(" ").join("-")}-schedule.pdf`})}>Download Schedule</div>
                        </div>

                        <div className='schedule-modal-body' ref={this.targetRef}>
                            <h1>{this.state.selectedTeam?.label} Game Schedule</h1>

                            <div className='team-schedule-container'>
                                {this.state.selectedSchedule?.map((sc, i)=> 
                                    <div className='schedule-row' key={i}>
                                        <div className='row-day'>
                                            <span>{format(sc.startTime, 'E')}</span>
                                            <span>{format(sc.startTime, 'MMM dd')}</span>
                                        </div>
                                        <div className='row-time'>
                                            {format(sc.startTime, 'h:mm b')}
                                        </div>

                                        <div className='row-team'>
                                            <span className='team'>vs {this.getAwayTeam(sc)}</span>
                                            <span className='location'>@ {sc.locationName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default LeagueSchedule;
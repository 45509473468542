export const rootPath = ( window.location.href.indexOf("localhost") > -1  ? "http://localhost:3714" : "");
export const mySessKey = "leeleeKidzDraftTourney_.";
export const storeKey = "leeleeKidz|league|store";

export const colourStyles = {
    control: (styles) => ({ 
        ...styles, backgroundColor: '#000', borderRadius: '50px', boxShadow: 'none !important', 
        '&:hover':{ borderColor: '#ee246e' },
        '&:focus':{ boxShadow: 'none', borderColor: '#ee246e' } 
    }),
    option: (styles, { isFocused }) => ({ 
        ...styles, backgroundColor: isFocused ? 'rgba(238,36,110,0.4)' : 'none' ,
        color: '#000', textTransform: 'capitalize'
    }),
    input: (styles) => ({ ...styles, backgroundColor: '#000', color:'#fff' }),
    singleValue: (styles) => ({ ...styles, color:'#fff', zIndex:1 }),
  
};

export function currencyFormat(price) {
    let ret = "";
    try {
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency', currency: 'USD',
        });

        ret = USDollar.format(price);
    }
    catch(ex){
        console.log(`Error Formatting Value: ${ex}`);
    }
    return ret;
}

export function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}

export function emptyList(num){
    if(!num) {
        return [];
    }

    let ret = [];
    for(let i=0; i < num; i++){
        ret.push(i+1);
    }
    return ret;
}

export function buildFinalInvoice(invoiceList){
    let ret = {};

    try {
        let invoice_details = [], total_item_count = 0,
            invoice_detail_display = [], price_sub_total = 0,
            league_count = 0, final_total = 0, discount_percentage = 0,
            discount_info = null;

        for(let i=0; i < invoiceList.length; i++){
            let invoiceListItem = invoiceList[i];

            // Parse Strings To Numbers
            let tmp_item_price = !isNaN(invoiceListItem.item_price) ? parseInt(invoiceListItem.item_price) : 0;
            let tmp_add_item_price = !isNaN(invoiceListItem.add_item_price) ? parseInt(invoiceListItem.add_item_price) : 0;
            let tmp_league_coaches = !isNaN(invoiceListItem.league_coaches) ? parseInt(invoiceListItem.league_coaches) : 0;
            let tmp_coach_price = !isNaN(invoiceListItem.coach_price) ? parseInt(invoiceListItem.coach_price) : 0;
            let has_jersey_info = (
                invoiceListItem?.jersey_info?.jersey_img?.length > 0 ||
                invoiceListItem?.jersey_info?.team_name?.length > 0 ||
                invoiceListItem?.jersey_info?.jersey_description?.length > 0 
            );

            // Set Default Item
            let invoice_item = { 
                title: invoiceListItem.title, 
                type: invoiceListItem.type, 
                item_price: tmp_item_price, 
                item_details: invoiceListItem.item_details,
                min_amount: invoiceListItem.min_amount, 
                sub_total: 0, 
                sub_item_count: 0,
                jersey_info: { ...invoiceListItem.jersey_info, has_jersey_info: has_jersey_info},
                add_item_price: tmp_add_item_price, 
                add_item_count: 0,
                start_dt:invoiceListItem?.start_dt, 
                end_dt: invoiceListItem?.end_dt,
                league_coaches: tmp_league_coaches, 
                coach_price: tmp_coach_price,
                
                /* Remove Before Submit */
                img_list: invoiceListItem.img_list, 
                detail_items: invoiceListItem.detail_items,
                list_title: invoiceListItem.list_title, 
                option_list: invoiceListItem.option_list,
                can_design: invoiceListItem.can_design, 
                max_coaches: invoiceListItem.max_coaches
            };

            let default_qty = 0;

            // Loop Through Invoice Item Details
            for(let j=0; j < invoiceListItem.item_details.length; j++){
                let tmp_qty = !isNaN(invoiceListItem.item_details[j].qty) ? parseInt(invoiceListItem.item_details[j].qty) : 0;

                total_item_count += tmp_qty;
                invoice_item.sub_item_count += tmp_qty;

                default_qty += tmp_qty;

                if(invoice_item.type === "league" && default_qty > invoice_item.min_amount) {
                    invoice_item.add_item_count += (default_qty - invoice_item.min_amount);
                    default_qty = invoice_item.min_amount;
                }
            }

            // Specific League Based Checks
            if(invoice_item.type === "league"){
                league_count++;
                invoice_item.sub_total += (invoice_item.add_item_count * tmp_add_item_price);

                // Add Coach Price
                if(invoice_item?.league_coaches > 0 && invoice_item?.coach_price > 0){
                    invoice_item.sub_total += (invoice_item.league_coaches * invoice_item.coach_price);
                }
            }

            invoice_item.sub_total += (default_qty * tmp_item_price);  

            price_sub_total += invoice_item.sub_total;
                
            invoice_details.push(invoice_item);
            invoice_detail_display.push(false);
        }

        // Perform Discount
        if(league_count > 2){
            // 20% Discount
            discount_percentage = 20;
            discount_info = { percentage: 20, title: `Registering 3 or more of our leagues` };
        }
        else if(league_count > 1){
            // 10% Discount
            discount_percentage = 10;
            discount_info = { percentage: 10, title: "Registering 2 of our leagues" };
        }

        // Set final total with any discounts
        final_total = price_sub_total - ((.01 * discount_percentage) * price_sub_total);

        ret = { 
            invoiceList: invoice_details, 
            total: final_total, 
            sub_total: price_sub_total,
            item_count: total_item_count,
            discount: discount_info
        }
    }
    catch(ex){
        console.log(`Building Final Invoice: ${ex}`);
    }

    return ret;
}